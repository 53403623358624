var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-page"},[_c('list-layout',{ref:"listLayout",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"tab-arr":_vm.tabArr,"on-fetch":_vm.getList,"on-delete":_vm.handleDelete,"on-put-back":_vm.handlePutBack,"on-update":_vm.upDateSort},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('list-filter',{attrs:{"filterForm":_vm.filterForm,"uploadFilter":_vm.refreshList}})]},proxy:true},{key:"item_image",fn:function(ref){
var row = ref.row;
return [(row.data_type === 0)?[_c('list-image',{attrs:{"fit":"cover","width":40,"border-radius":"50%","src":row.image}})]:[(row.res[0])?_c('list-image',{attrs:{"fit":"cover","width":40,"border-radius":"50%","src":row.res[0].thumbnail || row.res[0].url}}):_c('list-image',{attrs:{"fit":"cover","width":40,"border-radius":"50%","src":""}})]]}},{key:"item_status_text",fn:function(ref){
var row = ref.row;
return [_c('ListStatusText',{attrs:{"text":row.status_text,"type":row.status_text === '销售中'
            ? 'success'
            : row.status_text === '待上架'
            ? 'warning'
            : 'info'}})]}},{key:"top",fn:function(){return [_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.addGoods}},[_vm._v("新增")])],1)]},proxy:true},{key:"tabletop",fn:function(){return [(['normal', 'waite_sale', 'no_sale'].includes(_vm.filterForm.tab))?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.shelfArr}},[_vm._v("批量上架")]):_vm._e(),(['normal', 'sale'].includes(_vm.filterForm.tab))?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.offShelfArr}},[_vm._v("批量下架")]):_vm._e(),_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":_vm.deletedArr}},[_vm._v("批量删除")]),(['deleted'].includes(_vm.filterForm.tab))?_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":_vm.putbackArr}},[_vm._v("批量还原")]):_vm._e()]},proxy:true},{key:"operation",fn:function(scope){return [(_vm.filterForm.tab !== 'deleted')?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.editRow(scope.row)}}},[_vm._v("编辑")]):_vm._e(),(scope.row.status === 0 && _vm.filterForm.tab !== 'deleted')?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.offShelfRow(scope.row.id)}}},[_vm._v("下架")]):_vm._e(),(scope.row.status !== 0 && _vm.filterForm.tab !== 'deleted')?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.shelfRow(scope.row.id)}}},[_vm._v(_vm._s(scope.row.status === 1 ? '上架' : '重新上架'))]):_vm._e()]}},{key:"item_price",fn:function(ref){
            var row = ref.row;
return [_vm._v(" "+_vm._s(row.price || '')+" "+_vm._s(row.price && row.integral ? '+' : '')+" "+_vm._s(row.integral ? row.integral + '积分' : '')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }