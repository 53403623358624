<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
      :on-delete="handleDelete"
      :on-put-back="handlePutBack"
      :on-update="upDateSort"
    >
      <template v-slot:filter>
        <list-filter
          :filterForm="filterForm"
          :uploadFilter="refreshList"
        ></list-filter>
      </template>
      <!-- <template slot="item_sort" slot-scope="{ row }">
        <PopoverInputEdit
          v-model="row.sort"
          input-type="number"
          :validator="validator"
          @confirm="upDateSort(row)"
        />
      </template> -->
      <template slot="item_image" slot-scope="{ row }">
        <template v-if="row.data_type === 0">
          <list-image
            fit="cover"
            :width="40"
            border-radius="50%"
            :src="row.image"
          />
        </template>

        <template v-else>
          <list-image
            v-if="row.res[0]"
            fit="cover"
            :width="40"
            border-radius="50%"
            :src="row.res[0].thumbnail || row.res[0].url"
          />
          <list-image
            v-else
            fit="cover"
            :width="40"
            border-radius="50%"
            src=""
          />
        </template>
      </template>
      <template slot="item_status_text" slot-scope="{ row }">
        <ListStatusText
          :text="row.status_text"
          :type="
            row.status_text === '销售中'
              ? 'success'
              : row.status_text === '待上架'
              ? 'warning'
              : 'info'
          "
        ></ListStatusText>
      </template>
      <template #top>
        <div style="margin-bottom: 20px">
          <el-button type="primary" size="small" @click="addGoods"
            >新增</el-button
          >
        </div>
      </template>
      <template #tabletop>
        <el-button
          type="primary"
          size="small"
          v-if="['normal', 'waite_sale', 'no_sale'].includes(filterForm.tab)"
          @click="shelfArr"
          >批量上架</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-if="['normal', 'sale'].includes(filterForm.tab)"
          @click="offShelfArr"
          >批量下架</el-button
        >
        <!-- <el-button
          type="primary"
          size="small"
          v-if="['normal', 'sale'].includes(filterForm.tab)"
          >批量改库存</el-button
        > -->
        <el-button type="danger" size="small" @click="deletedArr"
          >批量删除</el-button
        >
        <el-button
          v-if="['deleted'].includes(filterForm.tab)"
          type="success"
          size="small"
          @click="putbackArr"
          >批量还原</el-button
        >
      </template>
      <template v-slot:operation="scope">
        <el-button
          v-if="filterForm.tab !== 'deleted'"
          type="text"
          size="small"
          @click="editRow(scope.row)"
          >编辑</el-button
        >
        <el-button
          v-if="scope.row.status === 0 && filterForm.tab !== 'deleted'"
          type="text"
          size="small"
          @click="offShelfRow(scope.row.id)"
          >下架</el-button
        >
        <el-button
          v-if="scope.row.status !== 0 && filterForm.tab !== 'deleted'"
          type="text"
          size="small"
          @click="shelfRow(scope.row.id)"
          >{{ scope.row.status === 1 ? '上架' : '重新上架' }}</el-button
        >
      </template>
      <template #item_price="{ row }">
        {{ row.price || '' }} {{ row.price && row.integral ? '+' : '' }}
        {{ row.integral ? row.integral + '积分' : '' }}
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListStatusText from '@/base/components/List/ListStatusText'
import ListLayout from '@/base/layout/ListLayout'
import ListFilter from '../components/ShopList/ListFilter'
import ListImage from '@/base/components/List/ListImage'
import PopoverInputEdit from '@/base/components/Popover/PopoverInputEdit'

import {
  getShopList,
  softDelete,
  deleteRow,
  putBack,
  changeStatus,
  updateListSort
} from '../api/shop-list'
export default {
  components: {
    ListFilter,
    ListLayout,
    ListStatusText,
    ListImage,
  },
  data() {
    return {
      validator: {
        type: 'integer',
        min: 1,
        message: '最小不能低于1的整数',
        trigger: 'blur',
      },
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '销售中', name: 'sale' },
        { label: '待上架', name: 'waite_sale' },
        { label: '已下架', name: 'no_sale' },
        { label: '回收站', name: 'deleted' },
      ],
      thead: [
        { type: 'selection' },
        { label: '排序', prop: 'sort', min_width: 80 },
        { label: '商品图片', prop: 'image', minWidth: 100, type: 'slot' },
        { label: '商品名称', prop: 'name', minWidth: 200 },
        { label: '支付类型', prop: 'pay_type_text', minWidth: 120 },
        {
          label: '商品分类',
          prop: 'category_name',
          minWidth: 120,
        },
        {
          label: '价格',
          prop: 'price',
          minWidth: 120,
          sortable: true,
          type: 'slot',
        },
        {
          label: '库存',
          prop: 'stocks',
          minWidth: 120,
        },
        {
          label: '销量',
          prop: 'orders_count',
          minWidth: 120,
        },
        {
          label: '商品状态',
          prop: 'status_text',
          minWidth: 200,
          type: 'slot',
        },
        {
          label: '数据类型',
          prop: 'data_text',
          minWidth: 200,
        },
        {
          label: '创建时间',
          prop: 'create_time',
          minWidth: 200,
          sortable: true,
        },
        { label: '操作', type: 'operation', width: 200 },
      ],
      // 筛选对象
      filterForm: {
        tab: 'normal',
        name: '', // 商品名
        data_type: -1, // 商品类型
        status: -1, // 商品状态
        order_by: '', // 排序字段
        is_desc: 1, // 正序还是倒序
        min_price: '', // 最低价
        max_price: '', // 最高价
        category_id: '', // 分类id
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: 15, //每页多少条数据
      },
      currentItem: {},
    }
  },
  methods: {
    upDateSort(data) {
      console.log(data)
      return updateListSort(data)
    },
    getList(data) {
      return getShopList(data)
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.$refs.listLayout.getList(this.filterForm)
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList()
    },

    editRow(row) {
      this.currentItem = row
      this.$router.push({
        name: 'GoodsDetail',
        params: {
          id: row.id,
        },
      })
    },
    // 多删
    deletedArr() {
      const selectArr = this.$refs.listLayout.selectArr
      if (selectArr.length > 0) {
        this.$msgbox
          .confirm('确定是否删除', '提示', {
            type: 'error',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
          .then((res) => {
            if (this.filterForm.tab === 'deleted') {
              deleteRow({ id: selectArr })
                .then((res) => {
                  this.$message.success(res.msg)
                  this.refreshList()
                })
                .catch((err) => {})
            } else {
              softDelete({ id: selectArr })
                .then((res) => {
                  this.$message.success(res.msg)
                  this.refreshList()
                })
                .catch((err) => {})
            }
          })
          .catch((err) => console.log(err))
      } else {
        this.$message.info('没有检测到勾选数据')
      }
    },
    // 批量上架
    shelfArr() {
      const selectArr = this.$refs.listLayout.selectArr
      if (!selectArr.length) {
        this.$message.info('没有检测到勾选数据')
        return
      }
      this.shelfRow(selectArr)
    },
    // 上架
    shelfRow(id) {
      changeStatus({ id, status: 0 }).then((res) => {
        this.$message.success('上架成功')
        this.refreshList()
      })
    },
    // 批量下架
    offShelfArr() {
      const selectArr = this.$refs.listLayout.selectArr
      if (!selectArr.length) {
        this.$message.info('没有检测到勾选数据')
        return
      }
      this.offShelfRow(selectArr)
    },
    // 下架
    offShelfRow(id) {
      changeStatus({ id, status: 2 }).then((res) => {
        this.$message.success('下架成功')
        this.refreshList()
      })
    },
    // 批量还原
    putbackArr() {
      const selectArr = this.$refs.listLayout.selectArr
      if (selectArr.length > 0) {
        this.$msgbox
          .confirm('确定是否还原', '提示', {
            type: 'success',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
          .then((res) => {
            putBack({ id: selectArr })
              .then((res) => {
                this.$message.success(res.msg)
                this.refreshList()
              })
              .catch((err) => {})
          })
          .catch((err) => console.log(err))
      } else {
        this.$message.info('没有检测到勾选数据')
      }
    },
    // 还原数据
    handlePutBack(data) {
      return putBack({ id: data })
    },
    // 数据删除
    handleDelete(idList, forever = false) {
      if (forever) {
        deleteRow({ id: idList })
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch((err) => {
            this.loading = false
          })
      } else {
        softDelete({ id: idList })
          .then((res) => {
            this.$message.success(res.msg)
            this.refreshList()
          })
          .catch((err) => {
            this.loading = false
          })
      }
    },
    // 新增商品
    addGoods() {
      // 跳转商品详情
      this.$router.push({
        name: 'GoodsDetail',
        params: {
          id: 0,
        },
      })
    },
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
}
</script>

<style lang="scss" scoped></style>
